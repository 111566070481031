<template>
  <video ref="videoPlayer" id="player" class="video-js vjs-theme-city" playsinline controls></video>
</template>
<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this.player);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
<style lang="scss">
.video-js {
  overflow: hidden;
  width: 100% !important;
  min-height: 88vh;
  @include respondTo(900px){
    min-height: 500px;
  }
  @include respondTo(500px){
    min-height: 300px;
  }
  .vjs-seek-to-live-control{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.vjs-paused{
    .vjs-big-play-button{
      display: block;
    }
  }
  .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .vjs-big-play-button{
    width: 80px;
    height: 80px;
    background: url('../../assets/img/svg/play.svg') center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    border: none;
    @include respondTo(900px){
      width: 50px;
      height: 50px;
    }
  }
  .vjs-big-play-button .vjs-icon-placeholder:before{
    display: none;
  }
  .vjs-poster{
    background-size: cover;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 330px;
      height: 290px;
      background-image: url('../../assets/img/livestream.png');
      background-size: cover;
      @include respondTo(900px){
        width: 230px;
        height: 200px;
      }
      @include respondTo(500px){
        width: 150px;
        height: 120px;
      }
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0,0,0,.5);
      @include respondTo(768px) {
        background: rgba(0,0,0,.4);
      }
    }
  }
}

</style>
