<template>
  <div class="stream_detail">
    <div class="stream_detail-container">
      <div class="stream_detail-wrap" :class="{ 'not-found': errorStatus }">
        <div v-if="!showPlayer" class="stream_detail-error">
          <div class="stream_detail-wrap">
            <div class="stream_detail-video">
              <div class="stream_detail-bg">
                <img src="@/assets/img/stream-bg.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="stream_detail-top" v-if="event && showPlayer">
          <video-player ref="videoPlayer" :options="videoOptions" />
        </div>
        <div v-if="event" class="stream_detail-schedule">
          <p>
            {{ formattedDate(event.event_datetime, 'DD MMM HH:mm') }}, {{ event.name }}
          </p>
          <span>
            0 {{ $t('labelWatching') }}
          </span>
        </div>
      </div>
      <div v-if="errorStatus" class="stream_detail-error">
        <div class="stream_detail-wrap">
          <div class="stream_detail-video">
            <div class="stream_detail-bg">
              <img src="@/assets/img/stream-bg.jpg" alt="">
            </div>
          </div>
          <div class="stream_detail-error_text">
            <h1>
              404
            </h1>
            <p>
              {{ $t('labelEventNotFound') }}
            </p>
          </div>
        </div>
      </div>
      <div class="stream_detail-app">
        <div class="stream_detail-app__left">
          <img alt="" src="@/assets/img/livestream-logo-small-inverted.png">
          <p>
            HOLA Live STREAM
          </p>
        </div>
        <div class="stream_detail-app__right">
          <button @click="handleRedirectForApp()">
            {{ $t('signIn') }}
          </button>
        </div>
      </div>
      <div style="display: none;">
        <div v-if="event" class="stream_detail-teams" id="event-info">
          <div class="stream_detail-teams__left team">
            <img :src="event.team_1.logo" alt="">
            <p>
              {{ event.team_1.name }}
            </p>
          </div>
          <div class="stream_detail-teams__score">
            <p class="stream_detail-teams__score-status">
              <span v-show="isShowTimer">{{ time }}</span>
              <span v-show="!isShowTimer">{{ timerText }}</span>
            </p>
            <div class="stream_detail-teams__score-count">
              {{ event.team_1_goals }}
            </div>
            <div class="stream_detail-teams__score-league">
              <img :src="event.league.logo" alt="">
            </div>
            <div class="stream_detail-teams__score-count">
              {{ event.team_2_goals }}
            </div>
          </div>
          <div class="stream_detail-teams__right team">
            <img :src="event.team_2.logo" alt="">
            <p>
              {{ event.team_2.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from '@/components/features/VideoPlayer.vue';

import {isAndroidDevice, isIOSDevice} from '@/helpers'
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'

const DEFAULT_PLAYER_OPTIONS = {
  muted: false,
  controls: true,
  language: 'en',
  playsinline: true,
  playToggle: true,
  subtitles: false,
  poster: require('@/assets/img/stream-bg.jpg'),
  preload: 'auto',
  liveui: false,
  html5: {
    hls: {
      overrideNative: true,
    },
    nativeVideoTracks: false,
    nativeAudioTracks: false,
    nativeTextTracks: false
  },
  controlBar: {
    liveDisplay: false,
    pictureInPictureToggle: false,
    subsCapsButton: false,
  }
}

// const videoPath = `https://nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8`
export default {
  name: "WatchMainView",
  components: {
    VideoPlayer,
  },
  head: {
    title: function () {
      return {
        inner: 'GoHere',
        separator: '|',
        complement: this.$t('labelWatch')
      }
    },
  },
  data() {
    return {
      errorStatus: null,
      showPlayer: false,
      requestInterval: null,
      isShowTimer: true,
      timer: null,
      timerText: null,
      timeInterval: null,
      videoOptions: {
        ...DEFAULT_PLAYER_OPTIONS
      }
    }
  },
  computed: {
    ...mapGetters({
      event: 'events/event',
      loading: 'event/isLoading'
    }),
    time() {
      if (moment(this.timer).isValid()) {
        const minutes = Math.floor(this.timer / 60)
        const seconds = this.timer % 60
        return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`
      }
      return ''
    }
  },
  async created() {
    await this.getEvent()
    this.showPlayer = true

    setTimeout(() => {
      this.setSourceToPlayer()
      this.appendEventScoreToVideoFrame()
    }, 200)

    this.requestInterval = setInterval(() => {
      this.getEvent()
    }, 10000)
  },
  methods: {
    ...mapActions({
      fetchEvent: 'events/GET_EVENT'
    }),
    handleRedirectForApp() {
      if (isAndroidDevice()) {
        const url = `intent://${location.origin.replace('https://', '')}/watch?v=${this.$route.query.v}#Intent;scheme=https;package=com.xgroup.live.league;end`
        window.location.replace(url);
      } else if (isIOSDevice()) {
        window.location.replace(`com.liveleague://main?event=${this.$route.query.v}`);
      }
    },
    appendEventScoreToVideoFrame() {
      // not working on ios with mode fullscreen
      const eventInfoEl = document.getElementById('event-info')
      const playerEl = document.getElementById('player')

      if (playerEl && eventInfoEl) {
        playerEl.appendChild(eventInfoEl)
      }
    },
    async getEvent() {
      try {
        await this.fetchEvent({id: this.$route.query.v})
        this.makeStreamInfo()
      } catch (err) {
        this.errorStatus = err.response.status
      }
    },
    async setSourceToPlayer() {
      const {hola_id, stream_id} = this.event
      const videoPath = `${process.env.VUE_APP_BROADCAST_LINK}${hola_id}/${stream_id}`
      let videoPathFromServer = ''
      const videoType = await fetch(videoPath).then(res => {
        const extension = res.url.split('.').pop()
        videoPathFromServer = res.url
        return extension === 'mp4' ? 'video/mp4' : 'application/x-mpegURL'
      })
      this.$refs.videoPlayer.player.src([{
        // type: 'application/x-mpegURL',
        type: videoType,
        src: videoPathFromServer,
      }])
    },
    makeStreamInfo() {
      this.videoOptions.liveui = true

      this.setStreamTime()
      this.setStreamStatus()
    },
    setStreamTime() {
      const {start_match, pause, resume, stop_match} = this.event.base_actions_time
      const defaultFormat = 'YYYY-MM-DD HH:mm:ss'
      const dateNowUTC = moment().utc()

      const activeFirstHalfGame = start_match && !pause && !resume
      const activeSecondHalfGame = start_match && pause && resume && !stop_match

      this.calculateAndRunTimer(activeSecondHalfGame, defaultFormat, start_match, dateNowUTC, resume, pause)

      if (activeFirstHalfGame || activeSecondHalfGame) {
        clearInterval(this.timeInterval)
        this.isShowTimer = true
      }
    },
    setStreamStatus() {
      const {start_match, pause, resume, stop_match} = this.event.base_actions_time

      // event pending (paused and no resume action)
      if (start_match && pause && !resume) {
        this.isShowTimer = false
        this.timerText = this.$t('labelPending')
      }

      // event not started(like as initial status)
      if (!start_match) {
        this.isShowTimer = false
        this.timerText = '00:00'
      }

      // event finished
      if (stop_match) {
        this.isShowTimer = false
        this.videoOptions.liveui = false
        this.timerText = this.$t('labelFinished')
      }
    },
    calculateAndRunTimer(activeSecondHalfGame, defaultFormat, start_match, dateNowUTC, resume, pause) {
      if (activeSecondHalfGame) {
        const startMatch = moment(pause).format(defaultFormat)
        const firstHalfDifference = moment(startMatch).diff(moment(start_match).format(defaultFormat))

        const differenceTime = moment(dateNowUTC).diff(moment(resume).format(defaultFormat))
        this.timer = Math.trunc(moment.duration(firstHalfDifference + differenceTime).as('seconds'))
      } else {
        const differenceTime = moment(dateNowUTC).diff(moment(start_match).format(defaultFormat))
        this.timer = Math.trunc(moment.duration(differenceTime).as('seconds'))
      }

      this.timeInterval = setInterval(() => {
        this.timer = this.timer + 1
      }, 1000)
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formattedDate(date, format) {
      return moment(date).format(format)
    },
  },
  beforeDestroy () {
    clearInterval(this.requestInterval)
    clearInterval(this.timeInterval)
  }
}
</script>
<style lang="scss">
@keyframes runningString {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.stream_detail {
  .vjs-user-active{
    .stream_detail-teams#event-info{
      bottom: 30px;
      transition: bottom .1s ease-out;
    }
  }
  .mobile {
    display: none;
    @include respondTo(768px) {
      display: block;
    }
  }

  .desktop {
    display: block;
    @include respondTo(768px) {
      display: none;
    }
  }
  &-wrap{
    &.not-found{
      display: none;
    }
  }
  &-error{
    text-align: center;
    color: #fff;
    z-index: 11;
    position: relative;
    &_text{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    h1{
      font-size: 150px;
      margin-bottom: 10px;
      @include respondTo(500px){
        font-size: 60px;
      }
    }
    p{
      font-size: 20px;
      @include respondTo(500px){
        font-size: 14px;
      }
    }
  }
  &-schedule {
    padding: 20px 10px 15px;
    font-size: 16px;
    font-weight: 500;
    @include respondTo(500px){
      font-size: 12px;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      margin-top: 5px;
      display: block;
      @include respondTo(500px){
        font-size: 8px;
      }
    }
  }
  &-video_placeholder {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 12px;
    padding-top: 15px;
    width: 100%;
    max-width: 350px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    z-index: 10;
  }
  &-top{
    position: relative;
  }
  &-teams {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    z-index: 3;
    &#event-info{
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      transition: bottom .1s ease-out .3s;
    }
    @include respondTo(800px){
      max-width: 180px;
    }
    &__score {
      width: 100%;
      max-width: 150px;
      border-radius: 3px;
      background: #fff;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include respondTo(800px){
        width: 82px;
        height: 25px;
      }
      &-status{
        position: absolute;
        bottom: -26px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: #13d44a;
        font-weight: bold;
        @include respondTo(800px){
          font-size: 7px;
          bottom: -15px;
        }
      }

      &-count {
        font-weight: bold;
        font-size: 14px;
        width: calc(50% - 15px);
        color: #000;
        @include respondTo(800px){
          font-size: 9px;
        }
      }

      &-league {
        img {
          max-width: 50px;
          max-height: 50px;
          @include respondTo(800px){
            max-width: 30px;
            max-height: 40px;
          }
        }
      }
    }

    .team {
      width: 100%;
      max-width: 100px;
      text-align: center;
      font-size: 0;
      @include respondTo(800px){
        max-width: 47px;
        margin-top: -3px;
      }
      img {
        max-width: 40px;
        max-height: 40px;
        @include respondTo(800px){
          max-width: 31px;
          max-height: 31px;
        }
      }

      p {
        margin-top: 10px;
        font-weight: bold;
        color: #fff;
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include respondTo(800px){
          margin-top: 4px;
          font-size: 7px;
        }
      }
    }
  }

  &-app {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: $black;
    display: none;
    @include respondTo(800px){
      display: flex;
    }

    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        max-width: 30px;
        margin-right: 10px;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
    }

    &__right {
      padding-left: 10px;
      button {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        border: 2px solid $green;
        padding: 5px 10px;
      }
    }
  }
  &-redirect-btn {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    button {
      max-width: 300px;
    }
  }
  &-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    img{
      @include respondTo(600px){
        max-width: 70%;
      }
    }
  }
  &-container{
    @include respondTo(1500px){
      padding: 0;
    }
  }
  &-bg{
    font-size: 0;
    position: relative;
    user-select: none;
    text-align: center;
    img {
      opacity: .8;
      min-height: 300px;
      width: 100%;
      max-height: 88vh;
      object-fit: cover;
      @include respondTo(768px) {
        object-fit: cover;
        opacity: 1;
      }
      @include respondTo(430px) {
        min-height: 244px;
      }
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0,0,0,.5);
      @include respondTo(768px) {
        background: rgba(0,0,0,.5);
      }
    }
  }
  &-running__string {
    background: #000;
    color: #fff;
    font-size: 20px;
    padding: 10px 0;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    overflow: hidden;

    p {
      animation: runningString infinite 15s linear;
      white-space: nowrap;
    }
  }
}
</style>
